<template>
  <div ref="app" id="app">
    <site-header v-if="restaurant" :restaurant="restaurant" />
    <router-view />
    <site-footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SiteHeader from "./components/siteHeader.vue";
import SiteFooter from "./components/siteFooter.vue";

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
  data() {
    return {
      restaurant: null,
    };
  },
  methods: {
    ...mapActions(["getUser", "getSelArea", "getSelCity"]),
    getRest(area) {
      let url =
        this.$store.state.baseURL +
        "/api/restaurants/" +
        area;
      let request = new Request(url, { method: "GET" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            this.restaurant = JSON.parse(JSON.stringify(r)).data.restaurants[0];
            this.$store.commit('setRestaurant',this.restaurant)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    $route(to, from) {
      if (to || from) console.log("routing");
      this.$store.dispatch("getUser");
      this.$store.dispatch("getSelArea");
      this.$store.dispatch("getSelCity");
    },
    "$store.state.selArea"(val) {
      this.getRest(val)
    },
  },
  beforeMount() {},
  mounted() {
    this.$store.dispatch("getUser");
    this.$store.dispatch("getSelArea");
    this.$store.dispatch("getSelCity");
    //this.getRest(this.$store.state.selArea);
  },
  updated(){
const app = this.$refs.app
if(app){
  app.scrollIntoView({behavior: "smooth"})
  window.appInit()
}
  }
};
</script>
<style>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
}*/
select:valid,textarea:valid,input:valid {
  border-bottom: default !important;
}
select:invalid,textarea:invalid,input:invalid {
  border-bottom: solid 1px red !important;
}
</style>