<template>
  <div
      class="
        osahan-menu-fotter
        fixed-bottom
        bg-white
        px-3
        py-2
        text-center
        d-none
      "
    >
      <div class="row">
        <div class="col selected">
          <a
            href="home"
            class="text-danger small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-home text-danger"></i></p>
            Home
          </a>
        </div>
        <div class="col">
          <a
            href="my_order"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-list"></i></p>
            My Orders
          </a>
        </div>
        <div class="col bg-white rounded-circle mt-n4 px-3 py-2">
          <div class="bg-danger rounded-circle mt-n0 shadow">
            <a
              href="checkout"
              class="text-white small font-weight-bold text-decoration-none"
            >
              <i class="feather-shopping-cart"></i>
            </a>
          </div>
        </div>
        <div class="col">
          <a
            href="contact-us"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-phone"></i></p>
            Help
          </a>
        </div>
        <div class="col">
          <a
            href="profile"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-user"></i></p>
            Profile
          </a>
        </div>
      </div>
    </div>
</template>
<script>
export default {

  name: 'subFooter',
  props: {
    
  }

}
</script>