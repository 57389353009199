import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    meta: { requiresAuth: false },
    component: Home
  }, {
    path: '/home',
    name: 'Home',
    meta: { requiresAuth: false },
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () => import('../views/login.vue')
  },
  {
    path: '/successful',
    name: 'successful',
    meta: { requiresAuth: false },
    component: () => import('../views/successful.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: { requiresAuth: true },
    component: () => import('../views/checkout.vue')
  },
  {
    path: '/my_order',
    name: 'my_order',
    meta: { requiresAuth: true },
    component: () => import('../views/my_order.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: { requiresAuth: true },
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/success_contact',
    name: 'success_contact',
    meta: { requiresAuth: true },
    component: () => import('../views/success_contact.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import('../views/profile.vue')
  },
  {
    path: '/success_save',
    name: 'success_save',
    meta: { requiresAuth: false },
    component: () => import('../views/success_save.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { requiresAuth: false },
    component: () => import('../views/signup.vue')
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    meta: { requiresAuth: false },
    component: () => import('../views/forgot_password.vue')
  },
  {
    path: '/change_password',
    name: 'change_password',
    meta: { requiresAuth: false },
    component: () => import('../views/change_password.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (window.localStorage.getItem("jwt")) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next()
  }
});
export default router
