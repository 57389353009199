<template>
  <div>
    <div v-show="menu">
      <home-menu-header
        v-if="branchs"
        :menu="menu"
        :branch="branch"
        :branchs="branchs"
      />
      <!-- <home-offers v-if="offers" :offers="offers" /> -->
      <div class="container">
        <div class="">
          <p></p>
          <!-- slider -->
          <div v-if="offers" class="trending-slider rounded">
            <div
              v-for="offer in offers"
              v-bind:key="offer.id"
              class="osahan-slider-item"
            >
              <div
                class="
                  list-card
                  bg-white
                  h-100
                  rounded
                  overflow-hidden
                  position-relative
                  shadow-sm
                "
              >
                <div class="list-card-image">
                  <a>
                    <img
                      :src="$store.state.baseURL + '/' + offer.image"
                      class="img-fluid item-img w-100"
                    />
                  </a>
                </div>
                <div class="p-3 position-relative">
                  <div class="list-card-body">
                    <h6 class="mb-1">
                      <a href="checkout" class="text-black">{{
                        offer.name_en
                      }}</a>
                    </h6>
                    <p class="text-gray mb-3">{{ offer.description_en }}</p>
                    <p v-for="size in offer.info" v-bind:key="size.id">
                      {{ size.size_en }}
                      <span class="text-gray m-0">
                        <span class="text-black-50">{{
                          size.price.price
                        }}</span>

                        <span
                          v-on:click="getExtras(offer.id, size.id)"
                          class="btn btn-outline-secondary btn-sm small m-2"
                          data-toggle="modal"
                          data-target="#extras"
                          >ADD</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Menu -->
      <div class="container position-relative">
        <div class="row" style="margin-bottom: 30px">
          <div v-if="menu" class="col-md-8 pt-3">
            <div class="shadow-sm rounded bg-white overflow-hidden">
              <div class="d-flex item-aligns-center">
                <p class="font-weight-bold h6 p-3 border-bottom mb-0 w-100">
                  {{ menu.name_en }}
                </p>
                <!-- <a class="small text-primary font-weight-bold ml-auto" href="#">View all <i class="feather-chevrons-right"></i></a> -->
              </div>
              <div class="row m-0">
                <h6 class="p-3 m-0 bg-light w-100"></h6>
              </div>
              <div
                class="
                  accordion
                  mb-3
                  rounded
                  shadow-sm
                  bg-white
                  overflow-hidden
                "
                id="accordionExample"
              >
                <div
                  v-for="section in menu.sections"
                  v-bind:key="section.id"
                  class="osahan-card bg-white border-bottom overflow-hidden"
                >
                  <div
                    class="osahan-card-header"
                    :id="'headingOne' + section.id"
                  >
                    <h2 class="mb-0">
                      <button
                        class="d-flex p-3 align-items-center btn btn-link w-100"
                        type="button"
                        data-toggle="collapse"
                        :data-target="'#collapseOne' + section.id"
                        aria-expanded="false"
                        :aria-controls="'collapseOne' + section.id"
                      >
                        <i class="feather-server mr-3"></i>
                        {{ section.name_en }}&nbsp;&nbsp;
                        <small class="text-black-50">
                          {{ section.items.length }} ITEMS</small
                        >
                        <i class="feather-chevron-down ml-auto"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    :id="'collapseOne' + section.id"
                    class="collapse"
                    :aria-labelledby="'headingOne' + section.id"
                    data-parent="#accordionExample"
                  >
                    <div class="osahan-card-body border-top p-3">
                      <div class="col-md-12 px-0 border-top">
                        <div class="">
                          <div
                            v-show="item.info.length > 0"
                            v-for="item in section.items"
                            v-bind:key="item.id"
                            class="p-2 border-bottom gold-members"
                          >
                            <div class="media">
                              <!-- <div class="mr-3 font-weight-bold text-danger non_veg">
                        .
                      </div> -->
                              <img
                                style="max-height: 80px; max-width: 80px"
                                alt="#"
                                :src="$store.state.baseURL + '/' + item.image"
                                class="mr-3 rounded-pill"
                              />
                              <div class="media-body">
                                <h6 class="mb-1">{{ item.name_en }}</h6>
                                <div class="myres-menu-item">
                                  <div
                                    v-for="size in item.info"
                                    v-bind:key="size.id"
                                  >
                                    <div
                                      style="
                                        padding: 0px 5px;
                                        height: 37px;
                                        border-top: 1px solid #ccc;
                                      "
                                      v-if="size && size.price"
                                      class="text-muted mb-0"
                                    >
                                      <span style="line-height: 35px">{{
                                        size.size_en
                                      }}</span>
                                      <span class="float-right">
                                        <span style="line-height: 35px">
                                          {{ size.price.price }}</span
                                        >&nbsp;&nbsp;
                                        <a
                                          v-on:click="
                                            getExtras(item.id, size.id)
                                          "
                                          href="#"
                                          class="
                                            btn btn-outline-secondary btn-sm
                                            small
                                          "
                                          data-toggle="modal"
                                          data-target="#extras"
                                          >ADD</a
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- checkout check details -->
          <i ref="scrollToMe"></i>
          <checkout-details
            v-if="order && branchs"
            :order="order"
            :actionBtnMethod="'checkout'"
            :branch="branch"
          />
        </div>
      </div>
      <!-- extras modal -->
      <div
        class="modal fade"
        id="extras"
        tabindex="-1"
        role="dialog"
        aria-labelledby="extraModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Extras and Options</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <form>
                <!-- extras body -->
                <div v-if="itemToAdd" class="recepie-body">
                  <!-- Options -->
                  <div
                    style="margin-top: 10px"
                    v-show="sizeToAdd.item_extras.length > 0"
                    class="
                      p-3
                      bg-primary bg-primary
                      mt-n3
                      rounded
                      position-relative
                    "
                  >
                    <h6 class="font-weight-bold m-0 text-white">Options</h6>
                  </div>
                  <div
                    v-for="option in sizeToAdd.item_extras"
                    v-bind:key="option.id"
                    class="border-bottom py-2"
                  >
                    <div class="p-3 m-0 bg-light w-100">
                      {{ option.category_en }}
                    </div>
                    <div
                      class="custom-control custom-radio"
                      :id="'subDiv' + option.id"
                    >
                      <div
                        style="padding: 3px 10px"
                        v-for="subOption in option.data"
                        v-bind:key="subOption.id"
                      >
                        <input
                          v-on:click="addOption(subOption, option)"
                          type="radio"
                          :id="'subExt' + subOption.id"
                          :name="'subExt' + option.id"
                          class="custom-control-input"
                          :checked="subOption.selected"
                        />
                        <label
                          class="custom-control-label"
                          :for="'subExt' + subOption.id"
                          >{{ subOption.name_en }}
                          <span class="text-muted">{{
                            subOption.price_en != 0
                              ? "+" + subOption.price_en + " L.E."
                              : "FREE"
                          }}</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- Extras -->
                  <div
                    style="margin-top: 10px !important"
                    v-show="itemToAdd.item_extras.length > 0"
                    class="
                      p-3
                      bg-primary bg-primary
                      mt-n3
                      rounded
                      position-relative
                    "
                  >
                    <h6 class="font-weight-bold m-0 text-white">Extras</h6>
                  </div>
                  <div
                    v-for="extra in itemToAdd.item_extras"
                    v-bind:key="extra.id"
                    class="border-bottom py-2"
                  >
                    <!-- <input
                    type="checkbox"
                    :id="'ex' + extra.id"
                    name="extra"
                    class="custom-control-input"
                  /> -->
                    <div class="p-3 m-0 bg-light w-100">
                      {{ extra.category_en }}
                    </div>
                    <div
                      :id="'opsSubDiv' + extra.id"
                      class="custom-control custom-checkbox"
                    >
                      <div
                        style="padding: 3px 10px"
                        v-for="subExtra in extra.data"
                        v-bind:key="subExtra.id"
                      >
                        <input
                          v-on:click="addExtra(subExtra)"
                          type="checkbox"
                          :id="'opsSubExt' + subExtra.id"
                          :name="'opsSubExt' + subExtra.id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          :for="'opsSubExt' + subExtra.id"
                          >{{ subExtra.name_en }}
                          <span class="text-muted">{{
                            subExtra.price_en != 0
                              ? "+" + subExtra.price_en + " L.E."
                              : "FREE"
                          }}</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <h6 class="font-weight-bold mt-4">Note</h6>
                  <div class="w-100 align-items-center">
                    <input
                      class="form-control"
                      type="text"
                      id="special"
                      name="special"
                      v-model="specialToAdd"
                      placeholder="Enter any special request note"
                    />
                  </div>
                  <h6 class="font-weight-bold mt-4">QUANTITY</h6>
                  <div class="d-flex align-items-center">
                    <p class="m-0">{{ countToAdd }} Item</p>
                    <div class="ml-auto">
                      <span class="count-number">
                        <button
                          v-on:click="
                            countToAdd > 1 ? countToAdd-- : (countToAdd = 1)
                          "
                          type="button"
                          class="btn-sm left dec btn btn-outline-secondary"
                        >
                          <i class="feather-minus"></i></button
                        ><input
                          class="count-number-input"
                          type="text"
                          readonly=""
                          :value="countToAdd" />
                        <button
                          v-on:click="countToAdd++"
                          type="button"
                          class="btn-sm right inc btn btn-outline-secondary"
                        >
                          <i class="feather-plus"></i></button
                      ></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer p-0 border-0">
              <div class="col-6 m-0 p-0">
                <button
                  type="button"
                  class="btn border-top btn-lg btn-block"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
              <div class="col-6 m-0 p-0">
                <button
                  v-on:click="addItem"
                  data-dismiss="modal"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :isLoading="isLoading"/>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import homeMenuHeader from "../components/homeMenuHeader.vue";
import CheckoutDetails from "../components/checkoutDetails.vue";
import Loading from "../components/loading.vue";

export default {
  components: { homeMenuHeader, CheckoutDetails, Loading },
  data() {
    return {
      offers: null,
      menu: null,
      menu_id: 1,
      price_list: 0,
      branchs: null,
      order: null,
      itemToAdd: null,
      sizeToAdd: null,
      countToAdd: 1,
      specialToAdd: null,
      extrasToAdd: [],
      optionsToAdd: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["selArea"]),
    curArea() {
      return this.$store.state.selArea;
    },
    branch() {
      var branchs = this.branchs;
      var notnull = branchs.filter((f) => f.area != null);
      var branch = notnull.find((c) => c.area.area_id == this.curArea);
      window.localStorage.setItem("branch", JSON.stringify(branch));
      return branch;
    },
  },
  methods: {
    getBranch(rest) {
      fetch(this.$store.state.baseURL + "/api/branches/" + rest.id, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.response) {
            this.result = response.data;
            this.branchs = JSON.parse(JSON.stringify(this.result)).branches;
          } else {
            if (response.message) console.log(response.message);
            else if (response.messages) console.log(response.messages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOffers() {
      fetch(
        this.$store.state.baseURL +
          "/api/offers/" +
          this.menu_id +
          "/" +
          this.price_list,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.offers = JSON.parse(JSON.stringify(this.result)).offers;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRestMenu(rest) {
      fetch(
        this.$store.state.baseURL + "/api/menu/" + this.curArea + "/" + rest.id,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.menu = JSON.parse(JSON.stringify(this.result)).menu;
          this.menu = this.menu[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExtras(itemId, sizeId) {
      fetch(
        this.$store.state.baseURL +
          "/api/item/" +
          itemId +
          "/" +
          this.price_list,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response;
          let item = JSON.parse(JSON.stringify(this.result));
          this.itemToAdd = item;
          this.sizeToAdd = item.info.find((c) => c.id == sizeId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addOption(subOption, option) {
      for (let i = 0; i < option.data.length; i++) {
        const el = option.data[i];
        el.selected = false;
      }

      Vue.set(subOption, "selected", true);

      let vv = [];
      let opts = this.sizeToAdd.item_extras;
      for (let index = 0; index < opts.length; index++) {
        const element = opts[index];
        for (let i = 0; i < element.data.length; i++) {
          const el = element.data[i];
          if (el.selected) {
            vv.push(el);
          }
        }
      }
      this.optionsToAdd = vv;
    },
    addExtra(subExt) {
      if (!subExt.selected) Vue.set(subExt, "selected", true);
      else Vue.set(subExt, "selected", false);

      let vv = [];
      let exts = this.itemToAdd.item_extras;
      for (let index = 0; index < exts.length; index++) {
        const element = exts[index];
        for (let i = 0; i < element.data.length; i++) {
          const el = element.data[i];
          if (el.selected) {
            vv.push(el);
          }
        }
      }
      this.extrasToAdd = vv;
    },
    addItem() {
      let optionsVal = 0.0;
      if (this.optionsToAdd) {
        for (let i = 0; i < this.optionsToAdd.length; i++) {
          const element = this.optionsToAdd[i];
          optionsVal = optionsVal + parseFloat(element.price_en);
        }
      }
      let extrasVal = 0.0;
      if (this.extrasToAdd) {
        for (let i = 0; i < this.extrasToAdd.length; i++) {
          const element = this.extrasToAdd[i];
          extrasVal = extrasVal + parseFloat(element.price_en);
        }
      }

      this.order.items.push({
        id: this.order.items.length + 1,
        name_en: this.itemToAdd.name_en,
        size_en: this.sizeToAdd.size_en,
        count: this.countToAdd,
        price: this.sizeToAdd.price.price,
        total: extrasVal + optionsVal + this.sizeToAdd.price.price,
        menu_item_id: this.itemToAdd.id,
        options: this.optionsToAdd,
        extras: this.extrasToAdd,
        special: this.specialToAdd,
      });
      //console.log(JSON.stringify(this.order.items));
      this.countToAdd = 1;
      this.itemToAdd = null;
      this.sizeToAdd = null;
      this.extrasToAdd = null;
      this.optionsToAdd = null;
      this.specialToAdd = "";
    },
    getCurOrder(menu) {
      let curOrder = window.localStorage.getItem("curOrder");

      if (curOrder && curOrder != "") {
        let infos = [];
        menu.sections.forEach((sec) => {
          sec.items.forEach((i) => {
            if (i.info) {
              i.info.forEach((inf) => {
                infos.push(inf);
              });
            }
          });
        });

        if (infos.length > 0) {
          let verfiyOrder = JSON.parse(curOrder);
          let order = JSON.parse(curOrder);
          order.items = [];

          verfiyOrder.items.forEach((item) => {
            let info = infos.find((i) => i.item_code == item.menu_item_id);
            //console.log(info.id)

            if (!info) {
              alert(
                "Item (" + item.name_en + ") was not found and will be removed"
              );
            } else {
              let exTotal = item.extras
                ? item.extras.reduce((total, obj) => {
                    return total + parseFloat(obj.price_en);
                  }, 0)
                : 0;
              let optTotal = item.options
                ? item.options.reduce((total, obj) => {
                    return total + parseFloat(obj.price_en);
                  }, 0)
                : 0;
              order.items.push({
                id: order.items.length + 1,
                name_en: item.name_en,
                size_en: item.size_en,
                count: item.count,
                price: info.price.price,
                total:
                  Math.round(exTotal * 100) / 100 +
                  Math.round(optTotal * 100) / 100 +
                  info.price.price,
                menu_item_id: info.item_code,
                options: item.options,
                extras: item.extras,
                special: item.special,
              });
            }
          });
          window.localStorage.setItem("curOrder", JSON.stringify(order));
          this.order = order;
        }
      } else {
        this.order = {
          items: [],
          notes: null,
          address: null,
        };
      }
    },
  },
  watch: {
    "$store.state.restaurant"(val) {
      if (val) {
        this.getRestMenu(val);
        this.getBranch(val);
        this.price_list = val.price_list;
      }
    },
    menu(val) {
      if (val) {
        this.getOffers();
        this.getCurOrder(val);
      }
    },
    sizeToAdd() {
      if (this.sizeToAdd) {
        let opts = this.sizeToAdd.item_extras;
        for (let index = 0; index < opts.length; index++) {
          const element = opts[index];
          for (let i = 0; i < element.data.length; i++) {
            const el = element.data[i];
            Vue.set(el, "selected", false);
          }
          element.data[0].selected = true;
        }
      }
    },
    itemToAdd() {
      if (this.itemToAdd) {
        let opts = this.itemToAdd.item_extras;
        for (let index = 0; index < opts.length; index++) {
          const element = opts[index];
          for (let i = 0; i < element.data.length; i++) {
            const el = element.data[i];
            Vue.set(el, "selected", false);
          }
        }
      }
    },
  },
  beforeUpdate() {
    this.$store.dispatch("getSelArea");
  },
  mounted() {
    //this.getRestMenu();
  },
  updated() {
    this.isLoading = false;
    let co = JSON.parse(window.localStorage.getItem("curOrder"));
    if (co) {
      if (co.items.length > 0) {
        const scel = this.$refs.scrollToMe;
        if (scel) {
          scel.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  },
};
</script>
<style>
.myres-menu-item > div:first-child > div {
  border-top: 0px transparent solid !important  ;
}
</style>