<template>
<div>
  <sub-footer />
  <footer class="section-footer border-top bg-dark">
    <div class="container">
      <section class="footer-top padding-y py-5">
        <div class="row">
          <aside class="col-md-4 footer-about">
            <article class="d-flex pb-3">
              <div v-if="$store.state.restaurant">
                <img :src="$store.state.baseURL+'/'+$store.state.restaurant.image" class="logo-footer mr-3" />
              </div>
              <div>
                <h6 class="title text-white">About Us</h6>
                <p class="text-muted">
                  Some short text about company like You might remember the Dell
                  computer commercials in which a youth reports.
                </p>
                <div class="d-flex align-items-center">
                  <a
                    class="btn btn-icon btn-outline-light mr-1 btn-sm"
                    title="Facebook"
                    target="_blank"
                    href="#"
                    ><i class="feather-facebook"></i
                  ></a>
                  <a
                    class="btn btn-icon btn-outline-light mr-1 btn-sm"
                    title="Instagram"
                    target="_blank"
                    href="#"
                    ><i class="feather-instagram"></i
                  ></a>
                  <a
                    class="btn btn-icon btn-outline-light mr-1 btn-sm"
                    title="Youtube"
                    target="_blank"
                    href="#"
                    ><i class="feather-youtube"></i
                  ></a>
                  <a
                    class="btn btn-icon btn-outline-light mr-1 btn-sm"
                    title="Twitter"
                    target="_blank"
                    href="#"
                    ><i class="feather-twitter"></i
                  ></a>
                </div>
              </div>
            </article>
          </aside>
          <!-- <aside class="col-sm-3 col-md-2 text-white">
            <h6 class="title">Error Pages</h6>
            <ul class="list-unstyled hov_footer">
              <li>
                <a href="not-found" class="text-muted">Not found</a>
              </li>
              <li>
                <a href="maintence" class="text-muted">Maintence</a>
              </li>
              <li>
                <a href="coming-soon" class="text-muted">Coming Soon</a>
              </li>
            </ul>
          </aside> -->
          <aside class="col-sm-3 col-md-2 text-white">
            <h6 class="title">Services</h6>
            <ul class="list-unstyled hov_footer">
              <!-- <li>
                <a href="faq" class="text-muted">Delivery Support</a>
              </li> -->
              <li>
                <a href="my_order" class="text-muted">My Orders</a>
              </li>
              <li>
                <a href="contact-us" class="text-muted">Contact Us</a>
              </li>
              <li>
                <a href="terms" class="text-muted">Terms of use</a>
              </li>
              <li>
                <a href="privacy" class="text-muted">Privacy policy</a>
              </li>
            </ul>
          </aside>
          <aside class="col-sm-3 col-md-2 text-white">
            <h6 class="title">For users</h6>
            <ul class="list-unstyled hov_footer">
              <!-- <li>
                <a href="favorites" class="text-muted"> Favorites </a>
              </li> -->
              <li>
                <a href="login" class="text-muted"> User Login </a>
              </li>
              <li>
                <a href="signup" class="text-muted"> User register </a>
              </li>
              <li>
                <a href="forgot_password" class="text-muted">
                  Forgot Password
                </a>
              </li>
              <li>
                <a href="change_password" class="text-muted">
                  Change Password
                </a>
              </li>
              <li>
                <a href="profile" class="text-muted"> Account Setting </a>
              </li>
            </ul>
          </aside>
          <!-- <aside class="col-sm-3 col-md-2 text-white">
            <h6 class="title">More Pages</h6>
            <ul class="list-unstyled hov_footer">
              <li>
                <a href="trending" class="text-muted"> Trending </a>
              </li>
              <li>
                <a href="most_popular" class="text-muted">
                  Most popular
                </a>
              </li>
              <li>
                <a href="restaurant" class="text-muted">
                  Restaurant Details
                </a>
              </li>
              <li>
                <a href="favorites" class="text-muted"> Favorites </a>
              </li>
            </ul>
          </aside> -->
        </div>
        <!-- row.// -->
      </section>
      <!-- footer-top.// -->
      <section style="display:none;" class="footer-center border-top padding-y py-5">
        <h6 class="title text-white">Countries</h6>
        <div class="row">
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">India</a></li>
              <li><a href="#" class="text-muted">Indonesia</a></li>
              <li><a href="#" class="text-muted">Ireland</a></li>
              <li><a href="#" class="text-muted">Italy</a></li>
              <li><a href="#" class="text-muted">Lebanon</a></li>
            </ul>
          </aside>
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">Malaysia</a></li>
              <li><a href="#" class="text-muted">New Zealand</a></li>
              <li><a href="#" class="text-muted">Philippines</a></li>
              <li><a href="#" class="text-muted">Poland</a></li>
              <li><a href="#" class="text-muted">Portugal</a></li>
            </ul>
          </aside>
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">Australia</a></li>
              <li><a href="#" class="text-muted">Brasil</a></li>
              <li><a href="#" class="text-muted">Canada</a></li>
              <li><a href="#" class="text-muted">Chile</a></li>
              <li><a href="#" class="text-muted">Czech Republic</a></li>
            </ul>
          </aside>
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">Turkey</a></li>
              <li><a href="#" class="text-muted">UAE</a></li>
              <li><a href="#" class="text-muted">United Kingdom</a></li>
              <li><a href="#" class="text-muted">United States</a></li>
              <li><a href="#" class="text-muted">Sri Lanka</a></li>
            </ul>
          </aside>
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">Qatar</a></li>
              <li><a href="#" class="text-muted">Singapore</a></li>
              <li><a href="#" class="text-muted">Slovakia</a></li>
              <li><a href="#" class="text-muted">South Africa</a></li>
              <li><a href="#" class="text-muted">Green Land</a></li>
            </ul>
          </aside>
          <aside class="col-sm-2 col-md-2 text-white">
            <ul class="list-unstyled hov_footer">
              <li><a href="#" class="text-muted">Pakistan</a></li>
              <li><a href="#" class="text-muted">Bangladesh</a></li>
              <li><a href="#" class="text-muted">Bhutaan</a></li>
              <li><a href="#" class="text-muted">Nepal</a></li>
            </ul>
          </aside>
        </div>
        <!-- row.// -->
      </section>
    </div>
    <!-- //container -->
    <section class="footer-copyright border-top py-3 bg-light">
      <div class="container d-flex align-items-center">
        <p class="mb-0">© 2020 Company All rights reserved</p>
        <p class="text-muted mb-0 ml-auto d-flex align-items-center">
          <a href="#" class="d-block"
            ><img alt="#" src="img/appstore.png" height="40"
          /></a>
          <a href="#" class="d-block ml-3"
            ><img alt="#" src="img/playmarket.png" height="40"
          /></a>
        </p>
      </div>
    </section>
  </footer>
  </div>
</template>

<script>
import subFooter from "./subFooter.vue";
export default {
  components: { subFooter },

  name: "siteFooter",
  props: {},
};
</script>