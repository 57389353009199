<template>
  <div>
    <site-nav />
    <header class="section-header">
      <section class="header-main shadow-sm bg-white">
        <div class="container">
          <div v-if="restaurant" class="row align-items-center">
            <div class="col-1">
              <a href="/home" class="brand-wrap mb-0">
                <img
                  class="img-fluid"
                  :src="$store.state.baseURL + '/' + restaurant.image"
                />
              </a>
              <!-- brand-wrap.// -->
            </div>
            <div class="col-3 d-flex align-items-center m-none">
              <div class="dropdown mr-3">
                <a
                  class="
                    text-dark
                    dropdown-toggle
                    d-flex
                    align-items-center
                    py-3
                  "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div>
                    <i
                      class="
                        feather-map-pin
                        mr-2
                        bg-light
                        rounded-pill
                        p-2
                        icofont-size
                      "
                    ></i>
                  </div>
                  <div v-if="areas">
                    <p class="text-muted mb-0 small">Select area</p>
                    {{ curAreaName }}
                  </div>
                </a>
                <div
                  style="
                    max-height: 487px;
                    overflow: hidden;
                    overflow-y: scroll;
                  "
                  class="dropdown-menu p-0 drop-loc"
                  aria-labelledby="navbarDropdown"
                >
                  <div class="osahan-country">
                    <div
                      style="position: sticky; top: 0px; z-index: 100"
                      class="search_location bg-primary p-3 text-right"
                    >
                      <div
                        class="input-group rounded shadow-sm overflow-hidden"
                      >
                        <div class="input-group-prepend">
                          <button
                            v-on:click="getAreas"
                            class="
                              border-0
                              btn btn-outline-secondary
                              text-dark
                              bg-white
                              btn-block
                            "
                          >
                            <i class="feather-search"></i>
                          </button>
                        </div>
                        <select
                          id="ddlCities"
                          v-model="selCity"
                          v-on:change="setSelCity"
                          placeholder="Enter Your Location"
                          class="shadow-none border-0 form-control"
                        >
                          <option
                            v-for="option in cities"
                            v-bind:key="option.id"
                            :value="option.id"
                          >
                            {{ option.name_en }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="p-3 border-bottom">
                      <a href="/home" class="text-decoration-none">
                        <p
                          v-if="cities && selCity"
                          class="font-weight-bold text-primary m-0"
                        >
                          <i class="feather-navigation"></i>
                          {{ cities.find((c) => c.id == selCity).name_en }},
                          Egypt
                        </p>
                      </a>
                    </div>
                    <div class="filter">
                      <h6 class="px-3 py-3 bg-light pb-1 m-0 border-bottom">
                        Choose your Area
                      </h6>
                      <div
                        v-for="item in areas"
                        v-on:click="setSelArea(item.id)"
                        v-bind:key="item.id"
                        class="custom-control border-bottom px-0 custom-radio"
                      >
                        <input
                          :value="item.id"
                          v-model="selArea"
                          type="radio"
                          :id="'area' + item.id"
                          name="area"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label py-3 w-100 px-3"
                          :for="'area' + item.id"
                          >{{ item.area_name_en }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- col.// -->
            <div class="col-8">
              <div class="d-flex align-items-center justify-content-end pr-5">
                <!-- home -->
                <a href="/home" class="widget-header mr-4 text-dark">
                  <div class="icon d-flex align-items-center">
                    <i class="feather-home h6 mr-2 mb-0"></i>
                    <span>Home</span>
                  </div>
                </a>
                <!-- search -->
                <!-- <a href="/search" class="widget-header mr-4 text-dark">
                  <div class="icon d-flex align-items-center">
                    <i class="feather-search h6 mr-2 mb-0"></i>
                    <span>Search</span>
                  </div>
                </a> -->
                <!-- offers -->
                <!-- <a
                href="/offers"
                class="widget-header mr-4 text-white btn bg-primary m-none"
              >
                <div class="icon d-flex align-items-center">
                  <i class="feather-disc h6 mr-2 mb-0"></i> <span>Offers</span>
                </div>
              </a> -->

                <!-- signin -->

                <a
                  href="/login"
                  v-show="JSON.stringify($store.state.curUser).length == 2"
                  class="widget-header mr-4 text-dark m-none"
                >
                  <div class="icon d-flex align-items-center">
                    <i class="feather-user h6 mr-2 mb-0"></i>
                    <span>Sign in</span>
                  </div>
                </a>
                <!-- signin -->
                <a
                  v-show="showCart > 0"
                  href="/checkout"
                  class="widget-header mr-4 text-dark"
                >
                  <div class="icon d-flex align-items-center">
                    <div
                      style="margin-top: -19px; margin-right: -24px !important"
                      class="
                        small
                        bg-dark
                        shadow-sm
                        rounded-pill
                        px-1
                        mx-1
                        text-white
                      "
                    >
                      {{ showCart }}
                    </div>
                    <i class="feather-shopping-cart h6 mr-2 mb-0"></i>

                    <span>Cart</span>
                  </div>
                </a>

                <!-- my account -->
                <div
                  v-if="$store.state.curUser.length > 2"
                  class="dropdown mr-4 m-none"
                >
                  <a
                    href="/#"
                    class="dropdown-toggle text-dark py-3 d-block"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      :src="
                        JSON.parse($store.state.curUser).avatar != 'default.png'
                          ? JSON.parse($store.state.curUser).avatar
                          : ''
                      "
                      class="
                        img-fluid
                        rounded-circle
                        header-user
                        mr-2
                        header-user
                      "
                    />
                    Hi {{ JSON.parse($store.state.curUser).user.user_name }}
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="/profile">My account</a>
                    <a class="dropdown-item" href="/my_order">My Orders</a>
                    <a class="dropdown-item" href="/contact-us">Contant us</a>
                    <a class="dropdown-item" href="/terms">Term of use</a>
                    <a class="dropdown-item" href="/privacy">Privacy policy</a>
                    <a class="dropdown-item" href="/login">Logout</a>
                  </div>
                </div>
                <a
                  class="toggle hc-nav-trigger hc-nav-1"
                  href="#"
                  role="button"
                  aria-controls="hc-nav-1"
                >
                  <span></span>
                </a>
              </div>
              <!-- widgets-wrap.// -->
            </div>
            <!-- col.// -->
          </div>
          <!-- row.// -->
        </div>
        <!-- container.// -->
      </section>
    </header>
    <div class="d-none">
      <div class="bg-primary border-bottom p-3 d-flex align-items-center">
        <a
          class="toggle togglew toggle-2 hc-nav-trigger hc-nav-1"
          href="#"
          role="button"
          aria-controls="hc-nav-1"
          ><span></span
        ></a>

        <h4 class="font-weight-bold m-0 text-white">
          <img
            class="img-fluid figure-img img-thumbnail"
            style="max-width: 50px"
            :src="$store.state.baseURL + '/' + restaurant.image"
          />
          {{ restaurant.res_name_en }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import siteNav from "./siteNav.vue";
export default {
  components: { siteNav },
  name: "siteHeader",
  data() {
    return {
      areas: null,
      selCity: this.$store.state.selCity,
      selArea: this.$store.state.selArea,
      cities: null,
    };
  },
  props: {
    user: String,
    restaurant: Object,
  },
  watch: {
    areas: {
      deep: true,
      handler() {
        if (this.areas) {
          if (this.areas.length > 0) {
            this.selArea = this.$store.state.selArea;
            if (!this.$store.state.selArea) {
              let id = this.areas[0].id;
              this.setSelArea(id);
            } else {
              this.$store.commit("curAreaName", this.curAreaName);
            }
          }
        }
      },
    },
    cities() {
      this.selCity = this.$store.state.selCity;
    },
    "$store.state.selCity"(val) {
      this.selCity = val;
      this.selArea = null;
      this.getAreas();
    },
    "$store.state.selArea"(val) {
      this.selArea = val;
    },
  },
  computed: {
    showCart() {
      let order = window.localStorage.getItem("curOrder");
      if (order) {
        if (order.length > 2) {
          if (JSON.parse(order).items.length > 0)
            return JSON.parse(order).items.length;
        }
      }
      return 0;
    },
    curAreaName() {
      var a = this.areas.find((c) => c.id == this.selArea);
      if (a) {
        return a.area_name_en;
      }
      return "";
    },
  },
  mounted() {
    this.getCities();
    this.getAreas();
  },
  methods: {
    getAreas() {
      fetch(this.$store.state.baseURL + "/api/areas?city=" + this.selCity, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.data) {
            this.result = response.data;
            this.areas = JSON.parse(JSON.stringify(this.result)).areas;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCities() {
      fetch(this.$store.state.baseURL + "/api/cities", {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.cities = JSON.parse(JSON.stringify(this.result)).cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSelArea(id) {
      let curOrder = window.localStorage.getItem("curOrder");
      if (curOrder) {
        if (curOrder.length > 0) {
          curOrder = JSON.parse(curOrder);
          if (curOrder.address) {
            curOrder.address = null;
            this.$store.commit('setCurOrder',JSON.stringify(curOrder))
            window.localStorage.setItem("curOrder", JSON.stringify(curOrder));
          }
        }
      }
      this.selArea = id;
      this.$store.commit("setSelArea", id);

      window.localStorage.setItem("selArea", id);
      this.$store.commit("curAreaName", this.curAreaName);
      //console.log(this.curAreaName);
    },
    setSelCity() {
      this.$store.commit("setSelCity", this.selCity);
      window.localStorage.setItem("selCity", this.selCity);
    },
  },
};
</script>