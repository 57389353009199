<template>
  <div>
    <div v-if="branchs" class="offer-section py-4">
      
      <div class="container position-relative">
        <img
        v-if="menu"
          v-show="menu"
          :src="$store.state.baseURL + '/' + menu.image"
          class="restaurant-pic img-thumbnail"
        />
        <div class="pt-3 text-white">
          <h2 class="font-weight-bold">{{ branch.name_en }} Branch</h2>
          <p class="text-white m-0">{{ branch.address_en }}</p>
          <p></p>
          <div v-if="false" class="rating-wrap d-flex align-items-center mt-2">
            <ul class="rating-stars list-unstyled">
              <li>
                <i class="feather-star text-warning"></i>
                <i class="feather-star text-warning"></i>
                <i class="feather-star text-warning"></i>
                <i class="feather-star text-warning"></i>
                <i class="feather-star"></i>
              </li>
            </ul>
            <p class="label-rating text-white ml-2 small">(245 Reviews)</p>
          </div>
        </div>
        <div class="pb-4">
          <div class="row">
            <div class="col-6 col-md-2">
              <p class="text-white-50 font-weight-bold m-0 small">Delivery</p>
              <p class="text-white m-0">
                {{
                  branch.area.delivery_fees == "0"
                    ? "FREE"
                    : branch.area.delivery_fees
                }}
                L.E.
              </p>
              <p class="text-white m-0">
                {{ branch.area.last_delivery }}
                <span class="muteed">(Last Order)</span>
              </p>
            </div>
            <div class="col-6 col-md-2">
              <p class="text-white-50 font-weight-bold m-0 small">Operation</p>
              <p class="text-white m-0">Open: {{ branch.open }}</p>
              <p class="text-white m-0">Close: {{ branch.close }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- red bar (hidden) -->
    <div v-show="false" class="container">
      <div class="p-3 bg-primary bg-primary mt-n3 rounded position-relative">
        <div class="d-flex align-items-center">
          <div class="feather_icon">
            <a
              href="#ratings-and-reviews"
              class="text-decoration-none text-dark"
              ><i
                class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-upload
                "
              ></i
            ></a>
            <a
              href="#ratings-and-reviews"
              class="text-decoration-none text-dark mx-2"
              ><i
                class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-star
                "
              ></i
            ></a>
            <a
              href="#ratings-and-reviews"
              class="text-decoration-none text-dark"
              ><i
                class="
                  p-2
                  bg-light
                  rounded-circle
                  font-weight-bold
                  feather-map-pin
                "
              ></i
            ></a>
          </div>
          <a href="contact-us" class="btn btn-sm btn-outline-light ml-auto"
            >Contact</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["menu", "branchs", "branch"],
};
</script>